import React from 'react'

const Nutrition = ({ nutA, nutB, nutC, nutD, nutE, nutF, nutG, nutH }) => (
<section class="performance-facts">
  <header class="performance-facts__header">
    <h1 class="performance-facts__title">Διατροφικές αξίες</h1>
    <p></p>
  </header>
  <table class="performance-facts__table">
    <thead>
      <tr>
        <th colspan="3" class="small-info">
        Ανά 100 γραμμάρια
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th colspan="2">
          <b>ΕΝΕΡΓΕΙΑ</b>
        </th>
        <td>
          {nutA}
        </td>
      </tr>
      <tr>
        <th colspan="2">
          <b>Λυπαρά</b>
        </th>
        <td>
          <b>{nutB}</b>
        </td>
      </tr>
      <tr>
        <td class="blank-cell">
        </td>
        <th>
          Εκ των οποίων κορεσμένα
        </th>
        <td>
          <b>{nutC}</b>
        </td>
      </tr>
      <tr>
        <th colspan="2">
          <b>Υδατάνθρακες</b>
        </th>
        <td>
          <b>{nutD}</b>
        </td>
      </tr>
      <tr>
        <td class="blank-cell">
        </td>
        <th>
          Εκ των οποίων σάκχαρα
        </th>
        <td>
          <b>{nutE}</b>
        </td>
      </tr>
      <tr>
        <th colspan="2">
          <b>Πρωτείνες</b>
        </th>
        <td>
          <b>{nutF}</b>
        </td>
      </tr>
      <tr>
        <th colspan="2">
          <b>Φυτικές ίνες</b>
        </th>
        <td>
          <b>{nutG}</b>
        </td>
      </tr>
      <tr>
        <th colspan="2">
          <b>Αλάτι</b>
        </th>
        <td>
          <b>{nutH}</b>
        </td>
      </tr>
    </tbody>
  </table>  
  <p class="small-info"><b></b></p>
  
</section>
)

export default Nutrition