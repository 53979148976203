import React from 'react'
import { Container } from 'reactstrap'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Nutrition from '../components/nutrition';
import Link from 'gatsby-link'
import { FaArrowLeft } from 'react-icons/fa';

export default function Template ({ data }) {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <div>
        <Helmet title={`${post.frontmatter.title} | ${data.site.siteMetadata.title}`} />
        <Container>
          <h1 className='display-3'>{post.frontmatter.title}</h1>
        </Container>
        <img src={post.frontmatter.thumbnail} alt={post.frontmatter.title} className="product-image"/>
        <Container dangerouslySetInnerHTML={{ __html: post.html }} className="col-lg-6 float-right systatika"/>
        <div class="container row benefitsContainer">
        <div className="col-lg-6">
          <h4>Οι μπάρες μας ειναι το ιδανικότερο σνακ για πρωινό, δεκατιανό ή απογευματινό γιατί είναι:</h4>
          <ul class="benefits">
            <li>πλούσιες σε ενέργεια</li>
            <li>πλούσιες σε φυτικές ίνες</li>
            <li>περιέχουν αντιοξειδωτικά</li>
            <li>χαμηλές σε θερμίδες</li>
            <li>χωρίς προσθήκη ζάχαρης και συντηρητικά</li>
            <li>χαμηλό γλυκαιμικό φορτίο</li>
            <li>χαμηλά κορεσμένα λιπαρά</li>
          </ul>
        Όλα τα παραπάνω καθιστούν τις μπάρες Σπιτικό εξαίρετη επιλογή για ένα υγιεινό, θρεπτικό, και απολαυστικό σνακ για όλους! Ξεκινήστε την μέρα σας, υγιεινά και νόστιμα σε πολλές γεύσεις!Οι χειροποίητες μπάρες Σπιτικό χαρίζουν όλη την ενέργεια που χρειαζόμαστε και τονώνουν τον μεταβολισμό μας !
        <p><b>Πιστοποιημένες με ISO 22000.</b></p>

        </div>
        <div className="col-lg-6">
          <Nutrition
            nutA={post.frontmatter.nuta}
            nutB={post.frontmatter.nutb}
            nutC={post.frontmatter.nutc}
            nutD={post.frontmatter.nutd}
            nutE={post.frontmatter.nute}
            nutF={post.frontmatter.nutf}
            nutG={post.frontmatter.nutg}
            nutH={post.frontmatter.nuth}
            />
          </div>
          </div>
      </div>
      <Link to="/χειροποιητες-μπαρες-δημητριακων" className="back-button"><FaArrowLeft className="back-icon"/> Μπάρες δημητριακών</Link>
    </Layout>
  )
}

export const barsPageQuery = graphql`
  query BarsPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html 
      frontmatter {
        path
        title
        thumbnail
        nuta
        nutb
        nutc
        nutd
        nute
        nutf
        nutg
        nuth
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
